// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/leaves-top-left.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/images/leaves-bottom-left.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/images/leaves-top-right.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/images/leaves-bottom-right.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scroll-container[data-v-a1db493a] {\n  height: calc(100vh - 64px); /* Full height of the viewport */\n  width: 100vw; /* Full width of the viewport */\n  overflow-y: scroll; /* Enable vertical scrolling */\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n  scroll-snap-type: y mandatory; /* Enable full section scroll snapping */\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-position: top left, bottom left, top right, bottom right;\n  background-repeat: no-repeat;\n  background-size: 30%;\n}\n@media (min-width: 768px) {\n.scroll-container[data-v-a1db493a] {\n    background-size: auto;\n}\n}\n\n/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */\n.scroll-container[data-v-a1db493a]::-webkit-scrollbar {\n  display: none;\n}\n.scroll-container > *[data-v-a1db493a] {\n  scroll-snap-align: start; /* Align children to the start */\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
