<template>
  <div class="scroll-container">
    <CoverPage :profile-data="memorialProfile" @scroll-to-second-page="handleScrollToSecondPage"/>
    <SecondPage :profile-data="memorialProfile"/>
    <EducationPage
      v-for="educationItem in (memorialProfile.sections || []).find(section => section.key === 'education')?.items || []"
      :key="educationItem.id"
      :education-data="educationItem"
    />
    <ImportantEventsPage
      v-for="eventItem in (memorialProfile.sections || []).find(section => section.key === 'important_events')?.items || []"
      :key="eventItem.id"
      :event-data="eventItem"
    />
    <WorkPage
      v-for="workItem in (memorialProfile.sections || []).find(section => section.key === 'work')?.items || []"
      :key="workItem.id"
      :work-data="workItem"
    />
    <AchievementsPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'achievements')?.items?.length > 0"
      :achievement-items="(memorialProfile.sections || []).find(section => section.key === 'achievements')?.items || []"
    />
    <FamilyPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'family')?.items?.length > 0"
      :family-data="(memorialProfile.sections || []).find(section => section.key === 'family')?.items || []"
    />
    <HobbiesPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'interests')?.items.length > 0"
      :hobby-items="(memorialProfile.sections || []).find(section => section.key === 'interests')?.items || []"
    />
    <AdditionalDescriptionPage
      v-for="additionalDescriptionItem in (memorialProfile.sections || []).find(section => section.key === 'additional_description')?.items || []"
      :key="additionalDescriptionItem.id"
      :additional-description-data="additionalDescriptionItem"
    />
    <GalleryPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'gallery')?.items?.length > 0"
      :gallery-data="(memorialProfile.sections || []).find(section => section.key === 'gallery')"
    />
    <FinalPage :profile-data="memorialProfile" @scroll-to-top="handleScrollToTop"/>
  </div>
</template>

<script>
import CoverPage from '@/views/MemorialProfilePages/CoverPage.vue';
import SecondPage from '@/views/MemorialProfilePages/SecondPage.vue';
import EducationPage from '@/views/MemorialProfilePages/EducationPage.vue';
import AchievementsPage from '@/views/MemorialProfilePages/AchievementsPage.vue';
import WorkPage from '@/views/MemorialProfilePages/WorkPage.vue';
import FinalPage from '@/views/MemorialProfilePages/FinalPage.vue';
import AdditionalDescriptionPage from '@/views/MemorialProfilePages/AdditionalDescriptionPage.vue';
import FamilyPage from '@/views/MemorialProfilePages/FamilyPage.vue';
import HobbiesPage from '@/views/MemorialProfilePages/HobbiesPage.vue';
import ImportantEventsPage from '@/views/MemorialProfilePages/ImportantEventsPage.vue';
import GalleryPage from '@/views/MemorialProfilePages/GalleryPage.vue';
import axiosInstance from "@/interceptors/axios.config";

export default {
  components: {
    CoverPage,
    SecondPage,
    EducationPage,
    FamilyPage,
    FinalPage,
    AdditionalDescriptionPage,
    WorkPage,
    AchievementsPage,
    HobbiesPage,
    ImportantEventsPage,
    GalleryPage,
  },
  data() {
    return {
      memorialProfile: {
        title: '',
        description: '',
      },
      apiUrl: process.env.VUE_APP_API_URL,
      error: null,
    };
  },
  mounted() {
    this.fetchMemorialProfile();
    this.setupScrollSnap();
  },
  methods: {
    async fetchMemorialProfile() {
      try {
        const slotNumber = this.$route.params.slotNumber;
        const response = await axiosInstance.get(`${this.apiUrl}mem-profile/${slotNumber}`);

        this.memorialProfile = {
          ...this.memorialProfile, // Spread existing properties
          ...response.data, // Spread response data
        };

        if (!this.memorialProfile.sections) {
          this.memorialProfile.sections = [];
        }

        if (!this.memorialProfile.sections.find(section => section.key === 'work')) {
          this.memorialProfile.sections.push({ category: 'work', items: [] });
        }
      } catch (error) {
        console.error(error);
      }
    },
    setupScrollSnap() {
      const scrollContainer = document.querySelector('.scroll-container');
      scrollContainer.style.scrollSnapType = 'y mandatory';
      scrollContainer.style.overflowY = 'scroll';
      scrollContainer.style.height = 'calc(100vh - 64px)';

      Array.from(scrollContainer.children).forEach(child => {
        child.style.scrollSnapAlign = 'start';
      });
    },
    handleScrollToTop() {
      const scrollContainer = document.querySelector('.scroll-container');
      scrollContainer.scrollTo({top: 0, behavior: 'smooth'});
    },
    handleScrollToSecondPage() {
      const secondPageElement = document.querySelector('.second-page-class'); // Use the actual class or ID of the second page
      if (secondPageElement) {
        secondPageElement.scrollIntoView({behavior: 'smooth'});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  height: calc(100vh - 64px); /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  overflow-y: scroll; /* Enable vertical scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-snap-type: y mandatory; /* Enable full section scroll snapping */
  background-image:
      url('../assets/images/leaves-top-left.png'),
      url('../assets/images/leaves-bottom-left.png'),
      url('../assets/images/leaves-top-right.png'),
      url('../assets/images/leaves-bottom-right.png');
  background-position: top left, bottom left, top right, bottom right;
  background-repeat: no-repeat;
  background-size: 30%;

  @media (min-width: 768px) {
    background-size: auto;
  }
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container > * {
  scroll-snap-align: start; /* Align children to the start */
}
</style>